import { Box, Typography } from "@mui/material";
import logobg from "../logo BG.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

function About() {
  const a1 = {
    fontSize: "17px",
    fontFamily: "Neue Montreal",
    textShadow: "0px 1px 5px rgba(26, 107, 105, 0.05)",
    letterSpacing: "0.065rem",
    lineHeight: "134.5%",
    fontWeight: "400",
    paddingBlock: "10px",
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingInline: { xs: "10px", md: "80px" },
          paddingTop: { xs: "100px", md: "1rem" },
          position: "relative",
        }}
        id="about"
      >
        <Box
          sx={{
            height: { xs: "100px", md: "100px" },
            width: "100px",
            objectFit: "fill",
            display: { xs: "none", md: "block" },
          }}
        >
          <img
            src={logobg}
            style={{
              position: "absolute",
              margin: "auto",
              left: "590px",
              top: "120px",
            }}
            height="auto"
            width="21%"
            alt=""
          />
        </Box>
        <Box>
          <ScrollAnimation animateIn="fadeInUp">
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "30px", md: "55px" },
                color: "#472D4C",
                fontFamily: "'Guttie' !important",
                lineHeight: { xs: "2rem", md: "120%" },
                fontWeight: "700",
              }}
            >
              {/* About Appricot Networking */}
              The Concept
            </Typography>
          </ScrollAnimation>
        </Box>

        <ScrollAnimation animateIn="fadeInUp">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                fontSize: { xs: "30px", md: "64px" },
                width: { xs: "80%", sm: "80%", md: "95%", lg: "72rem" },
                color: "#764B7E",
                marginTop: "40px",
                textAlign: "start",
              }}
            >
              <Typography sx={a1}>
                Appricot is more than just another Social-Networking-Platform.
              </Typography>
              <Typography sx={a1}>
                We want to build a network, that connects people and helps them
                to realize their projects. The concept of Appricot was created
                in 2022, to find a solution for people that don't have the
                budget to pay for services they need.
              </Typography>
              <Typography sx={a1}>
                Appricot Networking gives a solution for its users, to exchange
                services without having to pay for it. Instead, you offer
                another service. That means that everyone can give and receive
                something.
              </Typography>
              <Typography sx={a1}>
                We believe that its important, to help the people in our
                community and to give the possibility, to share their skills,
                resources and talents with each other. All of us can profit
                through the exchange of skills and build a strong and connected
                community.
              </Typography>
              <Typography sx={a1}>
                We are convinced that we make an important input for an
                inclusion and healthy community with our platform, and we are
                happy to be part of it. We invite you to be a part of Appricot
                Networking and we are glad to welcome you in our network.
              </Typography>
              <Typography sx={a1}>
                Download the Appricot Networking App now and use premium
                features for free!
              </Typography>
            </Box>
          </Box>
        </ScrollAnimation>
      </Box>
    </>
  );
}
export default About;
