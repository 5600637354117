import backimage from "../Banner BG.png";
// import bannerimage from "../Banner.png";
import Frame23 from "../Frame 23.png";
import Frame22 from "../Frame 22.png";
import { Typography } from "@mui/material";
import video from "../video2.mp4";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { useEffect, useRef, useState } from "react";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

function Home() {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);
  return (
    <>
      <Box id="home">
        <Box
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <img
            src={backimage}
            style={{
              position: "absolute",
              top: "10px",
              left: "0px",
            }}
            width="100%"
            alt=""
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            marginTop: { xs: "4rem", sm: "6rem" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-evenly" },
            alignItems: { xs: "center", md: "center" },
          }}
        >
          <ScrollAnimation animateIn="fadeInLeft">
            <Box
              palette={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                // ml: { xs: 0, md: -15 },
                // justifyContent: { xs: "center" },
                // alignItems: { xs: "center", md: "none" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column" },
                  // justifyContent: { xs: "center", md: "center" },
                  alignItems: { xs: "center", md: "normal" },
                  // pr:{xs:0,md:4,lg:0}
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "35px", md: "54px" },
                    color: "#472D4C",

                    textAlign: { xs: "center", md: "start" },
                    fontFamily: "'Guttie' !important",
                    lineHeight: { xs: "2rem", md: "3.5rem" },
                    fontWeight: "500",
                    marginTop: { xs: "3rem", md: "0px" },
                    zIndex: "1000",
                    width: { xs: "90%", sm: "28rem", md: "28rem", lg: "28rem" },
                  }}
                >
                  The Next Level Social Network
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "19.5px",
                      lineHeight: "130%",
                      color: "#764B7E",
                      marginTop: "20px",
                      fontFamily: "'Neue Montreal'",

                      fontWeight: 400,
                      textAlign: "start",

                      width: { xs: "80%", sm: "80%", md: "28rem", lg: "28rem" },
                    }}
                  >
                    Appricot Networking is a service exchange app for barter
                    services, where private and business users can exchange
                    services, without having to pay for it. Instead, you offer
                    another service! Find you needing services in our service
                    marketplace and offer a service by sharing your skills!
                    While also having the network benefit, you save a lot of
                    money for all kinds of services. From IT, carpenters,
                    cleaners, hairdressers, painters, plumbers, tilers to
                    teaching - and that for free!
                    <br />
                    <span>
                      Why pay for a service when you can barter something.
                      <br />
                      Download the Appricot Networking App now and use our
                      premium features for free!
                    </span>
                  </Typography>
                </Box>

                {/* <Typography
                  sx={{
                    fontSize: "19px",
                    lineHeight: "130%",
                    color: "#1D1920",
                    marginTop: "20px",
                    fontFamily: "'Neue Montreal'",
                    fontWeight: 700,
                    textAlign: { xs: "center", md: "start" },
                  }}
                >
                  App Coming Soon
                </Typography> */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: { xs: "center", sm: "center", md: "start" },
                  alignItems: { xs: "center", sm: "normal" },
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    height: { xs: "3rem", md: "50px" },
                  }}
                >
                  <img
                    src={Frame23}
                    alt=""
                    height="100%"
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.appricot.app",
                        "_blank"
                      );
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    height: { xs: "3rem", md: "50px" },
                    marginLeft: { xs: "0", sm: "1rem" },
                    marginTop: { xs: "1rem", sm: "0" },
                  }}
                >
                  <img
                    src={Frame22}
                    alt=""
                    height="100%"
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/us/app/appricot-networking/id1669037510",
                        "_blank"
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeInRight">
            <Box
              sx={{
                marginTop: { xs: 4, md: 1 },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  // minWidth: { xs: 200, md: 250, lg: 400 },
                  height: { xs: 500, sm: 500, md: 500 },
                  flexGrow: { xs: 4, md: 1 },
                  objectFit: "contain",
                }}
              >
                <video
                  ref={videoParentRef}
                  autoPlay={true}
                  loop={true}
                  controls={false}
                  muted={true}
                  playsInline
                  height="100%"
                  style={{
                    objectFit: "contain",
                    borderRadius: "7px",
                    boxShadow: "0 0 2px rgba(0, 0, 0, 0.20)",
                  }}
                >
                  <source
                    src={video}
                    type="video/mp4"
                    width="100%"
                    style={{
                      overflow: "visible",
                    }}
                  />
                </video>
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
      </Box>
    </>
  );
}
export default Home;
